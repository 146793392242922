import React from "react"
import Layout from "../components/Base/Layout"
import NewsArticle from "../components/News/newsArticle"

export default function({ pageContext }) {
  return (
    <Layout>
      <NewsArticle slug={pageContext.slug} />
    </Layout>
  )
}
